import React, { useState, useEffect, useMemo, useRef, useCallback, useLayoutEffect } from "react";
import { Stage, useGLTF, OrbitControls, useTexture, Environment, ScrollControls, Scroll, useScroll, Html } from "@react-three/drei";
import { Physics, RigidBody, CapsuleCollider, CuboidCollider, } from "@react-three/rapier";
import { HandAnimation } from "./HandAnimation";
import { Perf } from "r3f-perf";
import * as THREE from "three"; // Import THREE.js
import { gsap } from "gsap";
import { useFrame, useThree } from "@react-three/fiber";
import {RuneDetails} from "./RuneDetails";
import { RUNE_NAMES } from "../utilities/constants";
import PopupMenu from "./PopupMenu";

export default function RuneFall({ mode , backEndData, showInstruction}) {
  const { scene } = useGLTF("../runes/Rune1.glb");
  const runeStoneTexture = useTexture("../runes/maintex.jpg");
  const bgTexture = useTexture("../BG.jpg");
  const bgBlurTexture = useTexture("../BG_blur.jpg");


  const runeCount = 24;

  const [selectableRuneCount, setSelectableRuneCount] = useState(1);
  const [visibleRunes, setVisibleRunes] = useState( Array(runeCount).fill(false) );
  const [enableRuneOnClick, setRuneOnClick] = useState(true);
  const [kinematicRunes, setKinematicRunes] = useState( Array(runeCount).fill(false) );
  const [runeClicked, setRuneClicked] = useState(Array(runeCount).fill(false));
  const [selectedRunesDetails, setSelectedRunesDetails] = useState([]);
  const [runeSelectedCount, setRuneSelectedCount] = useState(0);
  const [scrollPageCount, setScrollPageCount] = useState(0);
  const [runeScale,setRuneScale] = useState(1);
  const bgMaterialRef = useRef();

  

 const symbolTextures = useTexture(Array.from({ length: RUNE_NAMES.length }, (_, i) => `../runes/rune_texture_with_name/${ RUNE_NAMES[i]}.png`));

 useEffect(() => {
   setSelectableRuneCount(mode.runes?.length);  
 }, [mode]);
 

 useEffect(() => {
let time =0;
  if(runeSelectedCount == 0) time = 7000;
  else time = 3000;
  setTimeout(()=>{
    showInstruction(selectableRuneCount-runeSelectedCount); 
  },time)  
}, [selectableRuneCount, runeSelectedCount]);



// const scroll = useScroll();
// useFrame(()=>{
//   console.log(scroll);
// })
   // Flip the textures on the y-axis
   useEffect(() => {
    if(symbolTextures)
    symbolTextures.forEach(texture => {
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.y = -1;
      texture.needsUpdate = true; // Ensure the texture updates
    });
  }, [symbolTextures]);

  const { camera } = useThree();

  const getRandomPositionInBag = (min, max) => {
    return [Math.random() * (max - min) - 1.5, 15, 1];
  };

  const getRandomVelocity = () => {
    return [
      Math.random() * 2 - 1.5, // Random x velocity
      Math.random() * -25, // Random downward y velocity
      Math.random() * 2 - 1.5, // Random z velocity
    ];
  };

 


  const [runeData, setRuneData] = useState(
    Array.from({ length: runeCount }, () => ({
      position: getRandomPositionInBag(0, 0.5), // Bag space dimensions
      velocity: getRandomVelocity(),
      opacity: 0
    }))
  );
  

  useEffect(() => {
    const startFalling = () => {
      let cumulativeDelay = 0;
      visibleRunes.forEach((_, index) => {
        let delay;
        if (index === 12) { delay = 220; } else { delay = 80; }
        cumulativeDelay += delay;
        setTimeout(() => {
          setVisibleRunes((prev) => {
            const newRunes = [...prev];
            newRunes[index] = true;
            return newRunes;
          });
          
        }, cumulativeDelay);
      });
    };
    const timer = setTimeout(startFalling, 3000); // Delay the start of the falling animation by 5 seconds
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);


  useEffect(() => {
    if(enableRuneOnClick && selectableRuneCount == runeSelectedCount)
      {
      setRuneOnClick(false);
      setScrollPageCount(parseInt(selectableRuneCount)+0.5);
      showDetailsOfRune();
      hideRemainingRunes();
    }
  },[selectedRunesDetails,enableRuneOnClick]);

  
  const updateRuneOpacity = (index, newOpacity) => {
    setRuneData((prev) =>
      prev.map((rune, i) => i === index ? { ...rune, opacity: newOpacity } : rune )
    );
  };

  const handleRuneClick = (event, index, rigidBodyRef, primitiveRef) => {
    setSelectedRunesDetails((prev) => [...prev, { index, rigidBodyRef,primitiveRef }]);

    event.stopPropagation();
    setRuneOnClick(false);
    setKinematicRunes((prev) => {
      const newKinematicRunes = [...prev];
      newKinematicRunes[index] = true;
      return newKinematicRunes;
    });

    setRuneClicked((prev) => {
      const newRuneClicked = [...prev];
      newRuneClicked[index] = true;
      return newRuneClicked;
    });

    setTimeout(() => {
      //console.log("kinematicRunes ", kinematicRunes);      
      if (rigidBodyRef.current) {
        highlightRunes(rigidBodyRef, index);
      }
    }, 100);
  };
  const highlightRunes = (rigidBodyRef, index) => {
    const runeMesh = rigidBodyRef.current;
    const timeline = gsap.timeline();
    const position = runeMesh.translation();
    let rotation = runeMesh.rotation();
  
    const targetPosition = new THREE.Vector3(0, 17, 10);
    const calculateTargetPosition2 = (index, totalObjects) => {
      const spacing =2; // Adjust the spacing between objects as needed
      const startX = (-(totalObjects - 1) * spacing) / 2; // Calculate starting position for centering
      return new THREE.Vector3(startX + index * spacing, 4, 10);
    };
  
    const targetPosition2 = calculateTargetPosition2(runeSelectedCount, selectableRuneCount); // Example for the first object
  
    setRuneSelectedCount(runeSelectedCount + 1);
  
    const direction = new THREE.Vector3();
    camera.getWorldDirection(direction);
    direction.negate(); // To make the object face the camera
  
    const targetQuaternion = new THREE.Quaternion().setFromEuler( new THREE.Euler((-Math.PI * 3) / 4, -Math.PI / 2, 0) );
    
    const targetQuaternion2 = new THREE.Quaternion().setFromEuler( new THREE.Euler(Math.PI /4, Math.PI / 2, 0) );
    const targetQuaternion3 = new THREE.Quaternion().setFromEuler( new THREE.Euler(0, Math.PI/2,0));
  
    timeline
      .to(position, {
        x: targetPosition.x, y: targetPosition.y, z: targetPosition.z,
        duration: 1, ease: "expo.inOut",
        onUpdate: () => runeMesh.setTranslation(position, true),
        onComplete: () => updateRuneOpacity(index, 1)
      }, 0)
      .to(rotation, {
        x: targetQuaternion.x, y: targetQuaternion.y, z: targetQuaternion.z, w: targetQuaternion.w,
        duration: 1, ease: "power2.inOut",
        onUpdate: () => runeMesh.setRotation(rotation, true)
      }, 0)
      .to(rotation, {
        x: targetQuaternion2.x, y: targetQuaternion2.y, z: targetQuaternion2.z, w: targetQuaternion2.w,
        duration: 1, ease: "power2.inOut",
        onUpdate: () => runeMesh.setRotation(rotation, true)
      })
      .to({}, { duration: 0.5, })
      .to(position, {
        x: targetPosition2.x, y: targetPosition2.y, z: targetPosition2.z,
        duration: 1, ease: "power2.inOut",
        onUpdate: () => runeMesh.setTranslation(position, true)
      })
      .to({}, { duration: 0.1,
        onComplete: () => {
          gsap.to(rotation, {
            x: targetQuaternion3.x, y: targetQuaternion3.y, z: targetQuaternion3.z, w: targetQuaternion3.w,
            duration: 0.5, ease: "power2.inOut",
            onUpdate: () => runeMesh.setRotation(rotation, true),
            onComplete: () => { setRuneOnClick(true) }
          });
       
          
        }
      }, 2.5);
  };
  
  //const [hovered, setHovered] = useState(false);
  const handlePointerOver = (meshRef, status) => {
    if (meshRef.current) {
      gsap.to(meshRef.current.children[0].material.color, {
        r: status ? 1 : 0, 
        g: status ? 1 : 0.13, 
        b: status ? 1 : 0, 
        duration: 0.5,
    });
    }
  };

  const showDetailsOfRune = ()=>{
    // if (bgMaterialRef.current) {
    //   bgMaterialRef.current.map = bgBlurTexture;
    //   bgMaterialRef.current.map.colorSpace = THREE.SRGBColorSpace;
    //   bgMaterialRef.current.needsUpdate = true; // Ensure the material updates
    // }
 
    camera.position.set(0, 0, 25);
    camera.lookAt(0, 0, 0);
    
 
    // const targetPosition = [new THREE.Vector3(-14, 0, 0), new THREE.Vector3(-14, -35, 0), new THREE.Vector3(-14, -70, 0),new THREE.Vector3(-14, -105, 0) ];



    const calculateTargetPosition=(size)=> {
      const vectorArray = [];
      for (let i = 0; i < size; i++) {
        vectorArray.push(new THREE.Vector3(-14, -i * 38, 0));
      }
      return vectorArray;
    }
    const targetPosition = calculateTargetPosition(runeSelectedCount); 
    

    const targetQuaternion = new THREE.Quaternion().setFromEuler( new THREE.Euler(Math.PI/2, Math.PI/2, 0) );
    

    selectedRunesDetails.map((item, index) => {
      const rigidBodyRef = item.rigidBodyRef.current;
      setRuneScale(6);


      const position = rigidBodyRef.translation();
      let rotation = rigidBodyRef.rotation();
      // console.log("rotation before gsap ", rotation)
      // console.log("target rotation before gsap ", targetQuaternion)
      gsap.timeline().to(position, {
          x: targetPosition[index].x, y: targetPosition[index].y, z: targetPosition[index].z,
          duration: 1, ease: "expo.inOut",
          onUpdate: () => rigidBodyRef.setTranslation(position, true)
        })
        .to(rotation, {
          x: targetQuaternion.x, y: targetQuaternion.y, z: targetQuaternion.z, w: targetQuaternion.w,
          duration: 1, ease: "power2.inOut",
          onUpdate: () =>{rigidBodyRef.setRotation(rotation, true)}
        },0)
    });
  };

  const hideRemainingRunes = ()=>{
    // console.log("Hide Remaining runes ", selectedRunesDetails);
    let _selecteRuneIndex = [];
    selectedRunesDetails.map((item, index) => {
      _selecteRuneIndex.push(item.index);
    });

    // console.log("selected rune index ", _selecteRuneIndex);

    visibleRunes.map((item,index) => {
      // console.log(index);
      if(!_selecteRuneIndex.includes(index))
        visibleRunes[index] = false;
    });
    // runes.map((item, i)=>{
    //   console.log("idex ",i);
    // })
   
  };

  const createModifiedScene = useCallback((index, opacity) => {
    const clonedScene = scene.clone();
    let symbolIndex = index;
    clonedScene.userData = {
      ...clonedScene.userData,
      name: RUNE_NAMES[symbolIndex],
      index: symbolIndex,
    };

    clonedScene.traverse((child) => {
      if (child.isMesh) {
        if (child.name === "Stone") {
          child.material = child.material.clone();
          child.material.color.set("darkgreen");
          child.material.map = runeStoneTexture;
          child.material.roughness = 0.15;
          child.material.metalness = 0.5;
        } else {
          child.material = child.material.clone();
          child.material.map = symbolTextures[symbolIndex];
          symbolIndex++;
          child.material.transparent = true;
          child.material.opacity = opacity;
        }
      }
    });
    return clonedScene;
  }, [scene, runeStoneTexture, symbolTextures]);


  const runes = runeData.map(({ position, velocity, opacity }, i) => {
    const rigidBodyRef = useRef();
    const primitiveRef = useRef();

    return (
      visibleRunes[i] && (
        <RigidBody
          ref={rigidBodyRef}
          type={kinematicRunes[i] ? "kinematicPosition" : "dynamic"}
          colliders={"hull"} ccd={false} restitution={0.02} friction={0.9}
          position={position} linearVelocity={velocity}
          key={i}
        >
          <primitive
          
            ref={primitiveRef} // Set the ref here
            object={createModifiedScene(i, opacity)}
            scale={runeScale}
            onClick={(event) =>
              enableRuneOnClick && !runeClicked[i] && handleRuneClick(event, i, rigidBodyRef, primitiveRef)
            }
            onPointerOver={()=> { enableRuneOnClick && !runeClicked[i] && handlePointerOver(primitiveRef,true)}}
            onPointerOut={()=> { enableRuneOnClick && !runeClicked[i] && handlePointerOver(primitiveRef,false)}}
          />
          {/* <CapsuleCollider  args={[0.15, 0.5]} restitution={0.05} friction={0.9}/> */}
        </RigidBody>
      )
    );
  });

  function Boundary() {
    return (
      <>
        <CuboidCollider type="fixed" args={[50, 1, 50]} position={[0, -4, 0]} />
        <CuboidCollider type="fixed" args={[20, 20, 1]} position={[-0, 0, -10]} />
        <CuboidCollider type="fixed" args={[20, 20, 1]} position={[0, 0, 6]} />
        <CuboidCollider type="fixed" args={[1, 20, 14]} position={[-15, 0, 0]} rotation={[0, -Math.PI / 10, 0]} />
        <CuboidCollider type="fixed" args={[1, 20, 14]} position={[15, 0, 0]} rotation={[0, Math.PI / 10, 0]} />
      </>
    );
  }


  return (
    <>
      {/* <OrbitControls/> */}
      <directionalLight position={[-2, 5, 3]} intensity={5} color="#FFA07A" />
      {/* <Perf position="top-left" /> */}
      <Environment preset="forest" />

      { scrollPageCount == 0 && <HandAnimation />}
      <ScrollControls pages={scrollPageCount} damping={0.25}>
        <Scroll>
          <Physics gravity={[0, -20, 0]}>
            {runes}
            <RigidBody type="fixed" restitution={0.02} friction={0.95}>
              <Boundary />
            </RigidBody>
          </Physics>
        </Scroll>
        <Scroll html>
          {scrollPageCount > 0 && (
            <RuneDetails
              mode={mode}
              data={backEndData}
              selectedRunes={selectedRunesDetails}
            />
          )}
        </Scroll>
      </ScrollControls>

      {scrollPageCount == 0 && (
        <mesh
          position={[0, -6, -5]}
          rotation-x={(-Math.PI / 2) * 0.55}
          scale={60}
        >
          <planeGeometry />
          <meshStandardMaterial ref={bgMaterialRef} map={bgTexture} />
        </mesh>
      )}
    </>
  );
}


 


