import { Environment, OrbitControls, Scroll, ScrollControls, Sparkles, SpotLight, useGLTF, useScroll, } from "@react-three/drei";
import "../styles/style.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import useStore from '../utilities/useStore';
import { useNavigate } from "react-router-dom";



export function RuneDetails({ mode, data, selectedRunes }) {
   
  const showPopup = useStore((state) => state.showPopup);
  const restartGame = useStore((state) => state.restartGame);

  const handleShow = () => showPopup(true);
  const handleRestart = () => restartGame();

  const [runeDetail, setRuneDetail] = useState([]);

  const addRuneDetails = (name, personalMeaning, generalMeaning) => {
    const newRuneDetail = { name, personalMeaning, generalMeaning };
    setRuneDetail((prevRuneDetail) => [...prevRuneDetail, newRuneDetail]); // Spread existing people and add new person
  };

  useEffect(() => {
    selectedRunes.map((item, index) => {
      const name = item.primitiveRef.current.userData.name;
      console.log("Name is ", name);
      const dataIndex = data.findIndex((rune) => rune.name === name);
      const personalMeaning = data[dataIndex][mode.runes[index]["personal_meaning"]];
      const generalMeaning = data[dataIndex][mode.runes[index]["general_meaning"]];
      addRuneDetails(name, personalMeaning, generalMeaning);
    });
  }, [selectedRunes]);

  return (
    <>
    <Container fluid className="vh-100 d-flex flex-column">
      <Row className="flex-grow-1" style={{ justifyContent: "end" }}>
        <Col md={8}>
          {runeDetail.map((page, index) => (
            <div key={index}
              style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "end", }} >
              <Card className="text-black bg-light border-info mb-4" style={{ opacity: 0.8 }} >
                <Card.Body>
                  <Card.Title>{page.name}</Card.Title>
                  <Card.Text style={{ fontWeight: "bold" }}>Persönliche Bedeutung:</Card.Text>
                  <Card.Text>{page.personalMeaning}</Card.Text>
                  <Card.Text style={{ fontWeight: "bold" }}>Allgemeine Bedeutung:</Card.Text>
                  <Card.Text>{page.generalMeaning}</Card.Text>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Col>
        <Row className="justify-content-center mt-4">
          <Col xs="auto">
            <Button variant="primary" onClick={handleShow}>Change Mode</Button>
          </Col>
          <Col xs="auto">
            <Button variant="secondary" onClick={handleRestart}>Restart</Button>
          </Col>
        </Row>
      </Row>
    </Container>
   </>
  ); }
