
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/style.css";
import React, { useEffect, useMemo, useState } from "react";
import { DB_URL } from "../utilities/constants";
import axios from "axios";
import PopupMenu from "./PopupMenu";
import RuneFall from "./RuneFall";
import Particles from "./Particles";
// import RuneDetails from "./RuneDetails";
import { SpriteMaterial, Sprite, TextureLoader } from 'three';

import useStore from '../utilities/useStore';

export default function Game({ props }) {
  const [backEndData, setbackEndData] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [mode, setMode] = useState({});
  const [instructionText, setInstructionText] = useState("");

  const setNavigate = useStore((state) => state.setNavigate);
  const navigate = useNavigate();

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate, setNavigate]);

  useEffect(() => {
    fetch(`../modes/mode${id}.json`)
      .then(response => response.json())
      .then(jsonData => setMode(jsonData))
      .catch(error => console.error('Error loading the data', error));
  }, [id]);

  useEffect(() => {
    axios
      .get(`${DB_URL}`)
      .then((response) =>{ setbackEndData(response.data);
        console.log("back end data is ", response.data);
      })
      .catch((error) => setError(error));
  }, []);




  function CameraSetup({ position, target, fov }) {
    const { camera } = useThree();
    useEffect(() => {
      camera.position.set(...position);
      camera.lookAt(...target);
      camera.fov = fov;
    }, [camera, position, target]);
    return null;
  }

function showInstruction(count){
  console.log("Remaining count ", count);
  if(count == 0)
    setInstructionText("")
  else if(mode.runes?.length ==1)
    setInstructionText(`Select a rune`)
  else if(count == 1)
    setInstructionText("Select last rune")
  else if(count == mode.runes?.length)
    setInstructionText(`Select ${count} runes`)
  else
    setInstructionText(`Select ${count} more runes`)
}




  return (
    <>    
      <Canvas>
      <CameraSetup position={[0, 20, 15]} target={[0, 0, 0]} fov={75} />
         <RuneFall key={id} mode={mode} backEndData={backEndData} showInstruction={showInstruction}/>
      </Canvas>
      <Popup data={backEndData} />  
     { instructionText.length >0 && <Instruction content={instructionText} />}
     <p>.</p>
    </>
  );
}

function Popup({ data }) {

  const isPopEnabled = useStore((state) => state.isPopEnabled);
  const showPopup = useStore((state) => state.showPopup);

  const handleClose = () => showPopup(false);
  return (
    <>
      <div className="App">
        <PopupMenu show={isPopEnabled} onClose={handleClose} />
      </div>     
    </>
  );
}


function Instruction ({content}){
  return (
    <>
      <div style={{ 
      position: 'absolute', 
      top: 0, 
      width: '100%', 
      backgroundColor: 'rgba(255, 255, 255, 0.4)', 
      zIndex: 10 
    }} className="d-flex justify-content-center align-items-center py-2">
        {/* <h2 className="display-3 text-center">{content}</h2> */}
        <p className="h2">{content}</p>
      </div>
    </>
  );
}

